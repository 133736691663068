<template>
    <section>
        <modal
        ref="modalRegistrarPago"
        no-cancelar
        no-aceptar
        :btns="disabled?[]:botones"
        titulo=""
        tamano="modal-lg"
        @accion="accionesBottones"
        >
            <div class="row mx-0 justify-content-between">
                <div class="col" style="width:130px;" />
                <div class="col f-600 text-center text-general">
                    Pedido ID.{{ info.id }}
                </div>
                <div class="col text-right">
                    <a class="cr-pointer text-general" style=";text-decoration:none;" @click="irVerPedido">
                        Ir al pedido
                    </a>
                </div>
            </div>
            <div class="row mx-0 justify-content-between">
                <div class="col d-middle justify-content-start">
                    <img :src="info.logo_mini" width="35px" height="35px" class="rounded-circle obj-cover" />
                    <p class="f-18 mx-2 text-general">
                        {{ info.nombre }}
                    </p>
                </div>
                <div class="col d-middle-center">
                    <p class="text-center text-general">
                        <span class="f-600">
                            {{ info.cant_productos }}
                        </span>
                        Productos
                    </p>
                </div>
                <div class="col d-middle justify-content-end">
                    <p class="text-right text-general">
                        <span class=" f-600">
                            Fecha
                        </span>
                        {{ formatearFecha(info.created_at, 'DD MMM Y') }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 justify-content-between">
                <div class="col text-general d-middle justify-content-start">
                    {{ info.direccion }} {{ info.direccion_anexo }}
                </div>
                <div class="col text-general d-middle-center">
                    {{ separadorNumero(info.valor_final, info.decimales) }}
                </div>
                <div class="col text-general d-middle justify-content-end">
                    {{ formatearFecha(info.created_at, 'hh:mm A') }}
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col mx-1 br-12 border-black py-1">
                    <p class="text-center">
                        <span class="f-600 text-general">
                            Total:
                        </span>
                        <span class="text-general f-600 mx-2">
                            {{ separadorNumero(info.valor_final, info.decimales) }}
                        </span>
                    </p>
                </div>
                <div class="col mx-1 br-12 border-black py-1">
                    <p class="text-center">
                        <span class="f-600 text-general">
                            Pago:
                        </span>
                        <span class="f-600 mx-2 text-general">
                            {{ separadorNumero(info.pago_valor, info.decimales, info.decimales) }}
                        </span>
                    </p>
                </div>
                <div class="col mx-1 br-12 border-black py-1">
                    <p class="text-center">
                        <span class="f-600 text-general">
                            Debe:
                        </span>
                        <span class="f-600 mx-2 text-general-red">
                            {{ separadorNumero(info.valor_final - info.pago_valor, info.decimales) }}
                        </span>
                    </p>
                </div>
                <div class="col-auto mx-1">
                    <el-tooltip v-if="$can('boton_tesoreria_leecheros_chat')" class="item" effect="light" content="Hablar con el cliente" placement="right-end">
                        <el-badge :value="info.cant_msg" :hidden="info.cant_msg==0" class="item">
                            <i class="icon-message-text-outline hover-icons f-22 cr-pointer" @click="AbrirChat" />
                        </el-badge>
                        <!-- <i class="icon-message-text-outline f-20 text-general cr-pointer" @click="AbrirChat" /> -->
                    </el-tooltip>
                </div>
            </div>
            <ValidationObserver v-if="info.pago_estado===2 && !disabled" ref="validacion">
                <div class="row mx-0 mt-3 justify-center">
                    <div class="col-4">
                        <ValidationProvider v-slot="{errors}" :rules="`required|cuota:${info.valor_final},${info.pago_valor}|max_value:16777215`" name="abono" class="d-flex flex-column">
                            <label class="f-15 text-general px-3 pl-5 text-general">
                                Abono
                            </label>
                            <div class="d-middle">
                                <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                    {{ info.sigla }}
                                </div>

                                <input-miles-moneda v-model="form.valor" :id-moneda="info.idm_moneda" placeholder="Digite un abono" maxlength="13" />
                                <!-- <el-input v-model="form.valor" placeholder="Digite un abono" /> -->
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4">
                        <label class="f-15 px-3 text-general">
                            Comentario
                        </label>
                        <ValidationProvider v-slot="{errors}" rules="max:300" name="comentario" class="d-flex flex-column">
                            <el-input v-model="form.comentario" placeholder="Digite un comentario" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div v-if="$can('boton_tesoreria_leecheros_registrar_pago')" v-loading="loading" class="col-3 d-flex align-items-end">
                        <div class="text-white br-12 py-2 px-2 text-center bg-general cr-pointer" @click="registrarPago">
                            Registrar Pago
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <div v-else-if="info.pago_estado===3" class="row mx-0 mt-3 justify-center bg-general3">
                <p class="text-muted text-white">El pedido está pagado</p>
            </div>
            <div v-else-if="info.pago_estado===4" class="row mx-0 mt-3 justify-center bg-general3">
                <p class="text-white">El pedido está anulado</p>
            </div>
            <div v-else-if="disabled" class="row mx-0 mt-3 justify-center">
                <p class="text-muted">Modo ver</p>
            </div>
            <div v-else class="row mx-0 mt-3 justify-center">
                <p class="text-muted">El pedido no está pendiente</p>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-7">
                    <p class="text-center text-general f-18 f-600">
                        Cuotas establecidas
                    </p>
                    <div v-for="(cuota, d) in cuotas_establecidas" :key="d" class="row mx-0 mt-2">
                        <div class="col-auto d-middle-center f-600">
                            {{ d + 1 }}.
                        </div>
                        <div
                        class="col br-12 py-2"
                        :style="`border: 1px solid ${cuota.estado ? '#DBDBDB' : ''}`"
                        >
                            <p>
                                {{ formatearFecha(cuota.fecha, 'DD MMMM Y') }} <i :class="iconosCuotas(cuota.estado)" />
                            </p>
                            <div class="row mx-0 justify-content-between">
                                <div class="col-5">
                                    <p class="tres-puntos">
                                        {{ separadorNumero(cuota.valor, info.decimales) }}
                                    </p>
                                </div>
                                <div class="col-auto pr-0">
                                    <p>
                                        {{ separadorNumero(cuota.valor_pendiente, info.decimales) }}
                                    </p>
                                </div>
                                <p class="col pl-2 tres-puntos">
                                    Saldo pendiente
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <p class="text-center text-general f-18 f-600">
                        Historial de pagos
                    </p>
                    <div v-for="(pago, idx) in historial_pagos" :key="idx" class="row mx-0 mt-2">
                        <div class="col border br-12 py-2">
                            <p class="f-15 text-general">
                                {{ formatearFecha(pago.created_at, 'DD MMMM Y') }}
                                <el-tooltip effect="light" :content="pago.comentario">
                                    <i class="icon-attention-alt f-16 text-general" />
                                </el-tooltip>
                            </p>
                            <p class="f-14 border-bottom text-general">
                                {{ separadorNumero(pago.valor, info.decimales) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Modal Anular -->
        <modal-anular-credito ref="modalAnular" @anular="credito_anulado" />
        <modalChat ref="modalChat" />
    </section>
</template>
<script>
import Creditos from '~/services/almacen/creditos'
import { extend } from 'vee-validate';
extend('cuota', {
    validate(value, {info}){
        const valor_final = info[0]
        const pago_valor = info[1]
        return parseInt(value) + parseInt(pago_valor) <= parseInt(valor_final)
    },
    params: ['info'],
    message: 'El {_field_} supera el valor del cŕedito.'
});
export default {
    components: {
        modalAnularCredito: () => import('./modalAnularCredito')
    },
    data(){
        return{
            loading: false,
            tendero: 1,
            info:{
                cant_productos: 0,
                created_at: null,
                id: 0,
                id_tienda: 0,
                logo_mini: null,
                nombre: '',
                valor_final: 0,
                pago_valor: 0,
                pago_estado: 0,
                created_by_tienda: 0,
                cant_msg: 0,
                sigla: '',
                idm_moneda: null,
                decimales: 0
            },
            disabled: false,
            buttons:[
                { texto: 'Cerrar', color: 'bg-light-f5 br-10 border text-general2', accion: 1},
                { texto: 'Anular Credito', color: 'bg-general br-10 border text-white', accion: 2}
            ],
            historial_pagos:[],
            cuotas_establecidas:[
                /* {
                    created_at: '20 Noviembre 2020' ,
                    valor_pendiente: 50000,
                    valor: 20000000000,
                    estado: 1
                },
                {
                    created_at: '18 Noviembre 2020' ,
                    valor_pendiente: 50000,
                    valor: 20000000000,
                    estado: 2
                },
                {
                    created_at: '11 Noviembre 2020' ,
                    valor_pendiente: 10000,
                    valor: 20000000000,
                    estado: 3
                } */
            ],
            form:{
                valor: null,
                comentario: '',
                // id_pedido_admin: null
            }
        }
    },
    
    computed: {
        botones(){
            let buttons = [
                { texto: 'Cerrar', color: 'bg-light-f5 br-10 border text-general2', accion: 1}
            ]

            if(this.$can('boton_tesoreria_leecheros_anular_credito')){
                buttons.push(
                    { texto: 'Anular Credito', color: 'bg-general br-10 border text-white', accion: 2}
                )
            }
            return buttons
        }
    },
    watch: {
        info(newValue){
            console.log(newValue);
        }
    },
    methods: {
        toggle(row, tendero=1, disabled=false){
            console.log('tendero', tendero);
            
            this.form.idm_moneda = null
            this.disabled = disabled
            this.limpiarForm()
            this.tendero = tendero
            if(tendero===1){ // logueado admin-desde módulo alamcen Admin
                this.listarDetalle(row.id_pedido_admin)
                this.form.id_pedido_admin = row.id_pedido_admin
            }else{ // aqui es desde un tendero
                // if(!disabled){
                this.listarDetalleCliente(row.id_pedido)
                // }
                this.form.id_pedido = row.id_pedido
            }
            this.$refs.modalRegistrarPago.toggle();
        },
        AbrirChat(){
            this.$refs.modalRegistrarPago.toggle();
            if(this.tendero===1){
                this.$refs.modalChat.toggle(this.info.created_by_tienda, 6) // 6 = tipo credito pedido -admin

            }else{
                this.$refs.modalChat.toggle(this.info.created_by_pedido, 6, false, 1) // 6 = tipo credito pedido -admin // utilizo el 1 solo para listar info cliente modal
            }
        },
        iconosCuotas(estado){
            switch(estado){
            case 1:
                return 'icon-ok-circled-outline accepted'
            case 2:
                return 'icon-attention-alt denied'
            }
        },
        AbrirAnular(){
            this.$refs.modalAnular.toggle(this.form, this.tendero)
            this.$refs.modalRegistrarPago.toggle();
        },
        accionesBottones(accion){
            if(accion == 1){
                this.$refs.modalRegistrarPago.toggle();
            } else if(accion == 2){
                this.AbrirAnular()
            }
        },
        async listarDetalle(id_pedido_admin){
            try {
                const {data} = await Creditos.detalleCreditosTienda(id_pedido_admin)
                this.info = data.info
                this.cuotas_establecidas = data.cuotas
                this.historial_pagos = data.pagos
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarDetalleCliente(id_pedido){
            try {
                const {data} = await Creditos.detalleCreditosCliente(id_pedido)
                this.info = data.info
                this.cuotas_establecidas = data.cuotas
                this.historial_pagos = data.pagos
            } catch (e){
                this.error_catch(e)
            }
        },
        async registrarPago(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campo obligatorio', 'warning')
                    return false
                }
                this.loading = true
                console.log('this.tendero', this.tendero);
                if(this.tendero===1){
                    var {data} = await Creditos.registrarPago(this.form)
                }else{// 2 menu credito logueado tendero
                    var {data} = await Creditos.registrarPagoCliente(this.form)
                }
                this.loading = false
                this.historial_pagos.push(data.pago)
                this.info.pago_valor = data.pedido.pago_valor
                this.info.pago_estado = data.pedido.pago_estado
                this.cuotas_establecidas = data.cuotas
                this.notificacion('Mensaje', 'Pago registrado', 'success')
                this.limpiarForm()
                this.$emit('registro',data.pago)
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        credito_anulado(){
            this.$emit('seanulo')
        },
        irVerPedido(){
            this.$refs.modalRegistrarPago.toggle();
            if(this.tendero===1){ //  logueado admin-desde módulo alamcen como tendero
                this.$router.push({name: `${this.$usuario.rol_nombre}.almacen.creditos.detalle.pedido`, params: { id_pedido_admin: this.form.id_pedido_admin}})
            }else{// aqui es desde un tendero pero para consultar un pedido hecho por el cliente
                this.$router.push({name: `${this.$usuario.rol_nombre}.almacen.creditos.detalle.pedido.cliente`, params: { id_pedido: this.form.id_pedido}})
            }
        },
        limpiarForm(){
            this.form.valor  = null
            this.form.comentario  = null
            if(this.$refs.validacion){
                this.$refs.validacion.reset()
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.orange-border{
    border: 1px solid #FF612B;
}
.blue-border{
    border: 1px solid #0892DD;
}
.red-border{
    border: 1px solid #FF0000;
}
.br-12{
    border-radius: 12px;
}
.accepted{
    color: #99E9C1;
}
.denied{
    color: #FF0000;
}
.slow{
    color: #0892DD;
}
</style>
